import Image from 'next/image'

const Goods = () => {
  return (
    <div className="container pt-48">
      <div className="text-center text-5xl font-bold text-white">灵缇精选</div>
      <div className="pb-16 pt-6 text-center text-xl font-bold text-white opacity-80">精品周边商品，灵缇互娱提供质量保障</div>
      <div className="grid grid-cols-2 gap-4 text-center lg:grid-cols-4">
        {['「灵缇互娱」专业赛事桌垫', '「灵缇互娱」专用扑克', '「灵缇互娱」专业赛事用报牌卡', '「灵缇互娱」掼神礼盒'].map((v, index) => (
          <div key={+index} className="group relative aspect-square overflow-hidden rounded-lg text-center">
            <Image
              src={`/goods/${index + 1}.png`}
              alt=""
              className="h-full w-full cursor-pointer rounded-lg object-cover transition-all duration-500 group-hover:scale-110"
              width={1000}
              height={1000}
              onClick={() => window.open('https://game.lingti.com/shop')}
            />
            <div className="absolute bottom-0 flex h-16 w-full items-end justify-center rounded-b-lg bg-gradient-to-t from-black/[0.7] px-4 pb-4 text-xs text-white opacity-0 transition-all group-hover:opacity-100 lg:rounded-b-xl lg:text-base">
              {v}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
export default Goods
