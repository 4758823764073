import useSWR from 'swr'
import { get } from '@/utils/request'
import { ArticleType } from '@/utils/types'
import { commonSWROptions } from './useAdmin'

const fetchNews = () =>
  get('articles').then((res) => {
    if (res.ok) {
      return res.articles
    }
    return []
  })

export const useNews = () => {
  return useSWR('news', () => fetchNews(), { ...commonSWROptions, fallbackData: [] }) as {
    data: ArticleType[]
    mutate: any
    isValidating: boolean
  }
}

export default useNews
