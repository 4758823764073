import useLogin from '@/hooks/swr/useLogin'
import { Space } from 'antd'
import Image from 'next/image'
import { useRouter } from 'next/router'
import Button from '../Button'

const FirstPage = () => {
  const router = useRouter()
  const { isLogin } = useLogin()
  return (
    <div className="container flex min-h-screen items-center justify-center">
      <div className="grid h-full w-full grid-cols-1 gap-4 place-self-center lg:grid-cols-5">
        <div className="col-span-1 flex flex-col items-center justify-center gap-16 text-white lg:col-span-2 lg:items-start">
          <div className="text-center lg:text-left">
            <div className="text-3xl font-bold lg:text-5xl">AI+电竞+智力运动</div>
            <div className="mt-6 text-3xl font-bold lg:text-5xl">综合服务商</div>
          </div>
          <div className="indent-8 text-lg opacity-80">
            <div>
              旗下拥有灵缇赛事编排信息化系统、灵缇复式发牌机、线上赛事系统、运动员管理及排名系统、灵缇电竞加速软件、协码啦效率软件、协码啦AI、牌例及 AI
              教学系统、扑克直播软硬件方案，并提供赛事、传媒、直播、文创等电竞+智力运动专业服务。
            </div>
            <div>从大众娱乐、顶流社交到职业竞技，灵缇互娱用AI、信息化、数字化等技术为您提供电竞+智力运动全场景解决方案。</div>
          </div>
          <Space size="large">
            <Button
              type="primary"
              onClick={() => router.push(isLogin ? '/match?is_create=1' : '/login?redirect=match?is_create=1')}
              shape="round"
              className="h-[40px] w-[120px] text-base font-semibold sm:h-[50px] sm:w-[200px] sm:text-xl"
            >
              立即办赛
            </Button>
            <Button
              type="link"
              size="large"
              onClick={() => window.open('https://xiemala.com/f/Dsxa1v')}
              className="h-[40px] w-[120px] text-base font-semibold text-white sm:h-[50px] sm:w-[200px] sm:text-xl"
            >
              联系我们
            </Button>
          </Space>
        </div>
        <div className="col-span-1 flex items-center justify-center lg:col-span-3 lg:-mt-60">
          <Image src="/lingti-charactor.png" alt="" className="h-auto w-full object-contain" width={2000} height={1200} />
        </div>
      </div>
    </div>
  )
}
export default FirstPage
