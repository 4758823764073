import Image, { StaticImageData } from 'next/image'

type ImageType = {
  src: StaticImageData
  alt: string
  width?: number
  height?: number
  fill?: boolean
  className?: string
}

const NextImage: React.FC<ImageType> = ({ src, width, alt, height, fill, className }) => {
  return <Image src={src} alt={alt} width={width} height={height} fill={fill} className={className} placeholder="blur" />
}

export default NextImage
