import { get } from '@/utils/request'
import useSWR from 'swr'

export const commonSWROptions = {
  revalidateOnFocus: false,
  refreshInterval: 900000,
}

export const focusedSWROptions = {
  revalidateOnFocus: true,
  revalidateOnReconnect: true,
  refreshInterval: 900000,
}

const useAdmin = (match_id?: string) => {
  const { data, mutate } = useSWR(`admin-${match_id}`, () => get('matches/gd_admin', match_id ? { match_id } : {}), commonSWROptions)
  return { isAdmin: data?.is_admin, mutate }
}

export default useAdmin
