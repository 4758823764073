import Image from 'next/image'

const Adv = () => {
  return (
    <div className="container min-h-screen">
      <div className="pb-16 pt-24 text-center text-3xl font-bold text-white lg:text-5xl">实时·安全·稳定·智能</div>
      <div className="grid grid-cols-1 gap-12 xl:grid-cols-1">
        <div className="grid grid-cols-1 gap-8 rounded-lg text-center md:grid-cols-2 lg:grid-cols-3">
          {[
            ['免费基础使用', '提供基础的免费自助赛事平台服务，我们提供基础教学视频，如需要获取更多教程，请随时联系我们。'],
            ['实时更新·无纸记分', '比分、桌次、复式牌次、成绩排名、队伍信息、签到情况等实时展示更新，支持万人级赛事，同时支持无纸化及纸质积分。'],
            ['现代美学·百种赛制', '独立的投屏控制中心，便于实时控制赛场渲染效果，具有现代美学的操作界面，立即提升赛事观感。'],
            ['安全备份', '安全可靠的数据备份策略，通过蜚语安全检测认证，在发生灾难性大型网络攻击时依然能够从容应对。'],
            ['全国一站式办赛', '灵缇互娱建设了全国合伙人体系，从场地协调到报名宣发再到赛事现场组织，为您提供一站式解决方案。'],
            ['严选周边', '专用扑克（德国科勒黑芯纸代理授权）、青藏冰泉、掼神酒、桌垫、牌桌、报牌卡等一应俱全。'],
          ].map((v, index) => (
            <div key={+index} className="flex flex-col items-center gap-8 rounded-lg bg-white px-4 py-8">
              <div className="m-auto h-16 w-16 rounded-full bg-sky-50">
                <Image src={`/adv/${index + 1}.png`} width={60} height={60} alt="" />
              </div>
              <div className="text-2xl font-semibold">{v[0]}</div>
              <div className="text-base text-gray-500">{v[1]}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
export default Adv
