import { Image } from 'antd'
import Marquee from 'react-fast-marquee'

const PhotoWall = () => {
  return (
    <div className="container hidden pt-48 lg:block">
      <div className="pb-16 text-center text-3xl font-bold text-white lg:text-5xl">精彩瞬间</div>
      <div className="text-black">
        <Marquee speed={100}>
          {Array.from({ length: 11 }).map((__, index) => (
            <div className="flex aspect-square items-center justify-center px-4" key={+index}>
              <Image src={`/photoWall/${index + 1}.png`} className="aspect-square h-auto w-full rounded-lg object-cover" alt="" preview={false} />
            </div>
          ))}
        </Marquee>
      </div>
    </div>
  )
}
export default PhotoWall
