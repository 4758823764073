/* eslint-disable react/destructuring-assignment */

import type { ButtonProps } from 'antd'
import { Button as AntdButton } from 'antd'
import debounce from 'lodash-es/debounce'
import omit from 'lodash-es/omit'
import { useMemo } from 'react'

// import styles from './index.module.css'

type PropType = {
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  width?: number | string
  buttonRef?: any
} & ButtonProps

const Button: React.FC<PropType> = (param) => {
  const { children, width, onClick, buttonRef } = param
  const others = omit(param, ['children', 'onClick', 'width', 'style', 'className'])
  const onClickThrottle = useMemo(
    () =>
      debounce(
        (e) => {
          if (onClick) onClick(e)
        },
        600,
        { leading: true, trailing: false }
      ),
    [onClick]
  )
  return (
    <AntdButton
      ref={buttonRef}
      style={{
        width,
        ...param.style,
      }}
      className={`${param.className}`}
      onClick={(e) => onClickThrottle(e)}
      {...others}
    >
      {children}
    </AntdButton>
  )
}

export default Button
