import useSWR from 'swr'
import { get } from '@/utils/request'
import { Partner } from '@/utils/types'
import { commonSWROptions } from './useAdmin'

const getPartners = () =>
  get('partners').then((res) => {
    if (res.ok) {
      return res.partners
    }
    return []
  })

const usePartners = () => {
  return useSWR('partners', getPartners, { ...commonSWROptions, fallbackData: [] }) as {
    data: Partner[]
    mutate: any
    isValidating: boolean
  }
}

const getPartnersByAdmin = () =>
  get('partners/fetch_partners_by_admin').then((res) => {
    if (res.ok) {
      return res.partners
    }
    return []
  })

export const usePartnersByAdmin = () => {
  return useSWR('admin-partners', getPartnersByAdmin, { ...commonSWROptions, fallbackData: [] }) as {
    data: Partner[]
    mutate: any
    isValidating: boolean
  }
}

export default usePartners
