import Image from 'next/image'
import dayjs from 'dayjs'
import { useNews } from '@/hooks/swr/useNews'
import { Card } from 'antd'

const News = () => {
  const { data } = useNews()
  return (
    <div className="container pb-24 pt-48">
      <div className="pb-16 text-center text-4xl font-bold text-white">互娱热讯</div>
      <Card className="bg-white/[0.8] backdrop-blur-sm lg:px-6 lg:py-8" bordered={false}>
        <div className="grid grid-cols-1 gap-3 text-center lg:grid-cols-2">
          {data.map((v, index) => (
            <div key={+index} className="relative my-2 flex items-center gap-4 rounded-lg text-center">
              <Image src={v.cover} alt="" className="cursor-pointer rounded-lg transition-all duration-500" width={60} height={60} onClick={() => window.open(v.link)} />
              <div className="flex h-[60px] flex-col items-start justify-between">
                <div role="presentation" className="cursor-pointer text-left font-bold hover:underline hover:opacity-70" onClick={() => window.open(v.link)}>
                  {v.title}
                </div>
                <div className="text-xs font-light">{dayjs(v.created_at).format('YYYY-MM-DD')}</div>
              </div>
            </div>
          ))}
        </div>
      </Card>
    </div>
  )
}

export default News
